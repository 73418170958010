import { Controller } from "@hotwired/stimulus"
import Quill from 'quill';

export default class extends Controller {

  connect() {
    this.element.innerHTML = this.quillInnerHTML();
  }

  quillInnerHTML() {
    if (this.params === '') {
      return;
    }

    let emptyElement = document.createElement('div');
    // debugger;
    const quill = new Quill(emptyElement);
    quill.setContents(this.params);
    return quill.root.innerHTML;
  }

  // Use getter to clean code
  get params() {
    return this.data.get('params');
  }
}
