import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import './src/quill'
import ImageResize from 'quill-image-resize-module';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import {EventBus} from './event_bus'

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const MAX_FILE_SIZE = 1000000

export default class extends Controller {
  static targets = ["editor", "input"]

  connect() {
    this.gonBlogHouses = gon.global.blogHouses;
    this.activeStorageRoutesPrefix = this.gonBlogHouses.active_storage_routes_prefix;
    this.appHostname = this.gonBlogHouses.app_hostname;

    this.quill = new Quill(this.editorTarget, {
    theme: 'snow',
    modules: {
      toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'link', 'image'],
          ['clean']
        ],
        imageResize: {
          displaySize: true,
          displayStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white'
          },
          modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },
        imageDropAndPaste: {
          // add an custom image handler
          handler: this.imageHandler.bind(this)
        }
      }
    });

    // More on this in a bit!
    this.quill.getModule('toolbar').addHandler('image', () => {
      // alert('asfasdf');
      this.importImage(this.quill);
    });


    this.quill.container.firstChild.innerHTML = this.inputTarget.value;

    this.quill.on('text-change', (delta, oldDelta, source) => {
      // console.log('get contents', JSON.stringify(this.quill.getContents()))
      this.inputTarget.value = this.quill.container.firstChild.innerHTML;
      EventBus.emit(`autoSaveQuill`, { value: this.inputTarget.value });
    });
  }

  /**
  * Do something to our dropped or pasted image
  * @param.imageDataUrl {string} - image's dataURL
  * @param.type {string} - image's mime type
  * @param.imageData {ImageData} - provided more functions to handle the image
  *   - imageData.toBlob() {function} - convert image to a BLOB Object
  *   - imageData.toFile(filename?: string) {function} - convert image to a File Object. filename is optional, it will generate a random name if the original image didn't have a name.
  *   - imageData.minify(options) {function)- minify the image, return a promise
  *      - options.maxWidth {number} - specify the max width of the image, default is 800
  *      - options.maxHeight {number} - specify the max height of the image, default is 800
  *      - options.quality {number} - specify the quality of the image, default is 0.8
  */
  imageHandler(imageDataUrl, type, imageData) {
    const file = imageData.toFile();
    this.uploadImage(this.quill, file);
  }

  importImage(textEditor) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      this.uploadImage(textEditor, file);
    };
  }

  isImageValidates(file) {
    var isFlag = true
    // Ensure only images are uploaded
    if (/^image\//.test(file.type)) {
      if (file.size > MAX_FILE_SIZE) {
        alert("Only support attachment files upto size 1MB!")
        isFlag = false
      }
    } else {
      alert('Only images allowed');
      isFlag = false
    }
    return isFlag
  }

  uploadImage(textEditor, file) {
    var fd = new FormData();
    fd.append('blob', file);

    if (!this.isImageValidates(file)) { return; }

    var upload = new DirectUpload(file, `${this.activeStorageRoutesPrefix}/direct_uploads`)
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        this.insertImage(
          textEditor,
          `${this.appHostname}${this.activeStorageRoutesPrefix}/blobs/${blob.signed_id}/${blob.filename}`
        );
      }
    });
  };

  insertImage(textEditor, fileUrl) {
    const range = textEditor.getSelection();
    textEditor.insertEmbed(range.index, 'image', fileUrl);
  };

  disconnect() {
    if (this.quill) {
      this.quill = null;
    }
  }

  focus() {
    this.quill.focus()
  }
}
